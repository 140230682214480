import React from "react";
import logo from "../assets/images/1978513938.png"; 
import { FaFacebook, FaTwitter, FaInstagram, FaPinterest } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { LiaEnvelopeOpenTextSolid } from "react-icons/lia";

const HeaderBanner = () => {
  return (
    <div>
      {/* Header */}
      <header className="bg-white shadow-md p-4 flex flex-wrap justify-between items-center">
        {/* Logo Section */}
        <div className="flex items-center justify-center w-full lg:w-auto mb-4 lg:mb-0">
          <a href="/" className="flex items-center space-x-2">
            <img
              src={logo}
              alt="Logo"
              className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] lg:w-[130px] lg:h-[130px]"
            />
          </a>
        </div>

        {/* Contact Details Section */}
        <div className="flex flex-col lg:flex-row flex-wrap justify-center items-center space-y-4 lg:space-y-0 lg:space-x-16 w-full lg:w-auto">
        {/* Email Section */}
        <div className="flex items-center">
          <LiaEnvelopeOpenTextSolid className="text-[#03076a] text-3xl md:text-4xl lg:text-5xl mr-2" />
          <div className="flex flex-col text-center lg:text-left">
            <a
              href="mailto:info@detectiveagency.com"
              className="text-sm md:text-lg font-medium text-[#1c1c1c] hover:text-blue-500 transition-all duration-300 ease-in-out relative z-10"
              onClick={(e) => console.log('Email link clicked!')}
            >
              info@detectiveagency.com
            </a>
            <span className="text-xs md:text-sm text-[#868686]">Send Email</span>
          </div>
        </div>
      
        {/* Address Section */}
        <div className="flex items-center">
          <IoLocationOutline className="text-[#03076a] text-3xl md:text-4xl lg:text-5xl mr-2" />
          <div className="flex flex-col text-center lg:text-left">
            <a
              href="https://www.google.com/maps/place/1018+VIP+Road+Zirakpur+Punjab"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm md:text-lg font-medium text-[#1c1c1c] hover:text-blue-500 transition-all duration-300 ease-in-out relative z-10"
              onClick={(e) => console.log('Location link clicked!')}
            >
              1018, CCC, Vip Road, Zirakpur 140603, (Punjab)
            </a>
            <span className="text-xs md:text-sm text-[#868686]">Visit Our Office</span>
          </div>
        </div>
      </div>

        {/* Social Media Section */}
        <div className="flex space-x-3 mt-4 lg:mt-0 w-full lg:w-auto justify-center relative z-10">
          <a
            href="https://www.facebook.com/profile.php?id=61566256785834"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100 hover:bg-blue-500 transition duration-300"
          >
            <FaFacebook className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
          <a
            href="https://x.com/home"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100 hover:bg-blue-500 transition duration-300"
          >
            <FaTwitter className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
          <a
            href="https://www.instagram.com/cluefinderd/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100 hover:bg-blue-500 transition duration-300"
          >
            <FaInstagram className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
          <a
            href="https://in.pinterest.com/cluefinderd/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100  hover:bg-blue-500 transition duration-300"
          >
            <FaPinterest className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
        </div>

      </header>
    </div>
  );
};

export default HeaderBanner;
