import React from "react";
import Banner from "../component/PersonalServices/PreMatrimional/PreMatBanner";
import TextContent from "../component/PersonalServices/PreMatrimional/PreMatri";

const PreMatrimonialPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default PreMatrimonialPage;
