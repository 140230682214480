// src/pages/Home.js
import React from 'react';
import Banner from '../component/AboutUs/Banner'
import ImageAbout from '../component/AboutUs/ImageAbout'

const About = () => {
  return (
    <div className="About-page">
      <Banner />
      <ImageAbout />
      
    </div>
  );
};

export default About;
