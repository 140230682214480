import React from "react";
import Banner from "../component/CorporateServices/PreEmplyoment/PreBanner";
import TextContent from "../component/CorporateServices/PreEmplyoment/PreEmp";

const PreEmploymentPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default PreEmploymentPage;
