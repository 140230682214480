import React from "react";
import { Link } from "react-router-dom";
import detectiveImage1 from '../assets/images/uploads/2054897019.jpg'; // Replace with your image path
import detectiveImage2 from '../assets/images/uploads/299859760.jpg'; 
import detectiveImage3 from '../assets/images/uploads/197919391.jpg'; // Replace with your image path
import detectiveImage4 from '../assets/images/uploads/466168883.jpg';

const DetectiveServices = () => {
  return (
    <div className="bg-gray-100 mt-[5vh] px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 px-5 sm:px-8">
        <div className="mt-0 mb-6">
          <h2 className="text-4xl mt-10 relative font-rajdhani font-bold text-[#1c1c1c]">
            <span className="text-blue-500">__</span>
            Corporate Detective Services          </h2>
        </div>
        <div className="mt-0 mb-6">
          <p className="text-[#868686] font-rajdhani text-xl leading-[25px] font-semibold">
          India’s only Private Detective Agency offering a fast, meticulous, and global service, gained from over 10 years of experience
          </p>
        </div>
      </div>

      {/* Card Grid Section */}
      <div className="max-w-8xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Map through detective services for dynamic generation */}
        {[
          {
            id: 1,
            image: detectiveImage1,
            title: "Pre Employment",
            link: "/pre-employment",
          },
          {
            id: 2,
            image: detectiveImage2,
            title: "Post Employment",
            link: "/post-employment",
          },
          {
            id: 3,
            image: detectiveImage3,
            title: "Labour Court Cases",
            link: "/labour-court-cases",
          },
          {
            id: 4,
            image: detectiveImage4,
            title: "Undercover Agent",
            link: "/undercover-agent",
          },
         
        ].map((service) => (
          <div
            key={service.id}
            className="relative group bg-white rounded-lg shadow-md overflow-hidden"
          >
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-64 object-cover group-hover:scale-110 transition-all duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent">
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 p-4 text-center text-white">
                <Link to={service.link}>
                  <h2 className="text-2xl font-bold whitespace-nowrap">{service.title}</h2>
                </Link>
                <div className="mt-4">
                  <Link to={service.link}>
                    <button className=" w-[50vh] py-2 text-lg bg-gray-800 text-white rounded-lg hover:bg-gray-700">
                      &rarr; Read More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetectiveServices;
