import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl p-6 mt-8 mx-auto">
    <h2 className="text-2xl font-semibold mb-4">Loyalty Checks</h2>
    <p className="text-[#1c1c1c] text-lg leading-7 mb-4">
      Trust is the cornerstone of any relationship, and Cluefinder Detective Agency offers professional Loyalty Checks to help you safeguard that trust. Whether in personal relationships or professional settings, our loyalty check services give you the clarity you need to move forward confidently.
    </p>
    <h2 className="text-2xl font-semibold mb-4">What We Investigate:</h2>
    <ul className="list-disc pl-6 text-[#1c1c1c] text-lg leading-7 mb-4">
      <li>✅ <span className="font-bold">Personal Loyalty</span> – Verify the faithfulness of your partner or spouse.</li>
      <li>✅ <span className="font-bold">Professional Loyalty</span> – Assess the commitment and integrity of employees or business associates.</li>
      <li>✅ <span className="font-bold">Behavioral Patterns</span> – Identify suspicious actions or inconsistencies in behavior.</li>
      <li>✅ <span className="font-bold">Hidden Relationships</span> – Uncover any secret connections or affairs.</li>
      <li>✅ <span className="font-bold">Digital Footprint</span> – Analyze social media and online activity for signs of disloyalty.</li>
    </ul>
    <p className="text-[#1c1c1c] text-lg leading-7 mb-4">
      At Cluefinder, we conduct thorough investigations with complete discretion, ensuring that your concerns are addressed with accuracy and sensitivity. We provide actionable evidence to help you make informed decisions.
    </p>
  </div>
  );
};

export default TextContent;
