import React from "react";
import imageCard from "../assets/images/uploads/personal.png";

const DetectiveServices = () => {
  return (
    <div className="bg-gray-100  relative"> {/* Removed 'mt-0' from here */}
      {/* Gray Background Section */}
      <div className="relative pb-20">
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
          {/* Left Section */}
          <div>
            <h2
              className="text-3xl md:text-4xl mb-6 relative"
              style={{
                fontFamily: "'Rajdhani', sans-serif",
                fontWeight: 800,
                color: "#1c1c1c",
              }}
            >
              <span className="text-[#03076a]">__</span>
              <span className="relative ml-2">Cluefinder Detective</span>
              <br />
              <span className="block ml-10 text-lg sm:text-2xl">
                Comprehensive Investigation Services
              </span>
            </h2>
          </div>

          {/* Right Section */}
          <div>
            <p className="text-[#868686] font-rajdhani text-sm md:text-lg ml-0 md:ml-8 leading-6 md:leading-8 font-semibold">
              Cluefinder Detective offers discreet and professional
              investigation services, including background checks, surveillance,
              fraud detection, and personal investigations. With expert
              detectives, we ensure thorough, confidential solutions tailored to
              your needs.
            </p>
          </div>
        </div>

        {/* Cards Section */}
        <div className="max-w-5xl mx-auto mt-[4vh] grid grid-cols-1 sm:grid-cols-2 gap-10 relative z-20">
          {/* First Card */}
          <div className="bg-white shadow-md border rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300">
            <div className="flex flex-col  items-center">
              <img
                src={imageCard}
                alt="Personal Detective"
                className="h-48 w-48 object-cover"
              />
              <h2 className="mt-4 text-black text-lg md:text-xl font-bold">
                Personal Detective Services
              </h2>
            </div>
            <ul className="mt-4 text-gray-600 px-4 text-center text-sm md:text-base">
              <li className="mb-1">Pre Matrimonial Investigation</li>
              <li className="mb-1">Post Matrimonial</li>
              <li className="mb-1">Loyalty Checks</li>
              <li className="mb-1">Divorce Case Investigation</li>
              <li className="mb-1">Physical Surveillance</li>
              <li className="mb-1">Background Check</li>
              <li>Sting Operation</li>
            </ul>
            <div className="bg-gray-200 mt-6 py-4 text-center">
              <button className="text-gray-800 text-sm font-medium">
                Learn More
              </button>
            </div>
          </div>

          {/* Second Card */}
          <div className="bg-white h-[65vh] md:h-[69vh] shadow-md border rounded-lg  hover:shadow-lg transition-all duration-300">
          <div className="flex flex-col items-center">
              <img
                src={imageCard}
                alt="Corporate Detective"
                className="h-48 w-48 object-cover"
              />
              <h2 className="mt-4 text-black text-lg md:text-xl font-bold">
                Corporate Detective Services
              </h2>
            </div>
            <ul className="mt-4 text-gray-600 px-4 text-center text-sm md:text-base">
              <li className="mb-1">Pre Employment</li>
              <li className="mb-1">Post Employment</li>
              <li className="mb-1">Labour Court Cases</li>
              <li>Undercover Agent</li>
            </ul>
            <div className="bg-gray-200 mt-6 py-4 text-center">
              <button className="text-gray-800 text-sm font-medium">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetectiveServices;