import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6">
    <h2 className="text-2xl font-semibold mb-4">Sting Operation by Cluefinder – Exposing the Hidden Truths</h2>
    <h3 className="text-[#1c1c1c] text-lg leading-7 mb-4">
      When you need to uncover covert activities or illegal practices,<strong> Cluefinder Detective Agency</strong> offers expert <strong>Sting Operations</strong> to gather undeniable evidence. 
      Our experienced team conducts carefully planned, discreet operations to expose fraudulent behavior, criminal activities, or unethical actions.
    </h3>
  
    <h4 className="text-xl font-medium mb-2">What We Investigate:</h4>
    <ul className="list-disc pl-6 text-lg leading-7">
      <li>✅ Fraud & Scams – Catch fraudsters in the act and protect your interests.</li>
      <li>✅ Corporate Misconduct – Investigate and reveal any unethical business practices.</li>
      <li>✅ Employee Theft – Expose theft, corruption, or dishonesty within organizations.</li>
      <li>✅ Blackmail & Extortion – Uncover blackmailers or extortionists to bring them to justice.</li>
      <li>✅ Cheating or Infidelity – Collect evidence of deceitful behavior in personal relationships.</li>
    </ul>
  
    <h3 className="text-[#1c1c1c] text-lg leading-7 mt-4">
      Using precise planning, advanced technology, and expert personnel, Cluefinder ensures that every sting operation is carried out with utmost professionalism and confidentiality. 
      We deliver clear, actionable results that can stand up in court.
    </h3>
  </div>
  );
};

export default TextContent;
