import React from "react";
import bannerImage from "../../assets/images/background/page-title.jpg";
import Navbar from "../Navbar"; // Import the Navbar component

const HeaderBanner = () => {
  return (
    <div>
      {/* Navbar Component */}
      <Navbar />

      {/* Banner Section */}
      <div className="relative h-full overflow-hidden">
        {/* Background Image with Overlay */}
        <div
          className="relative h-[300px] sm:h-[400px] lg:h-[500px] bg-cover bg-center flex items-center justify-center"
          style={{
            backgroundImage: `url(${bannerImage})`,
          }}
        >
          {/* Transparent Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Text Content */}
          <div className="relative text-center z-10 px-4">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white">
              Latest Blog
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
