import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import Image from '../../assets/images/resource/contact-1.jpg';
const ContactUsPage = () => {
  return (
    <div>
      {/* Banner Image */}
      <div className="relative mt-20 h-[50vh] bg-cover bg-center" style={{ backgroundImage: `url(${Image})` }}></div>

      {/* Contact Section */}
      <div className="container mx-auto my-10 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Side Contact Information */}
          <div className="space-y-6 ml-[30vh]">
          <h2
  className="text-5xl mb-9 relative transition-all duration-500 ease-linear"
  style={{
    fontFamily: "'rajdhani sans-serif",
    fontWeight: 800,
    color: "#1c1c1c",
    margin: 0,
  }}
>
<span className="text-[#03076a]">__</span>
<span className="relative ml-2">
Leave Us A
  </span>
  <br />
  <span className="block ml-16">Message</span></h2>
            <p>There are many variations of lorem ipsum free text ipsum available.</p>
            
            {/* New Contact Information with Icons */}
            <div className="space-y-2  mt-2">
              <p className="flex items-center py-4 border-b-2 mt-6">
                <FaPhoneAlt className="text-[#03076a] text-xl mr-2" />
                <a href="tel:+919646430343" className="hover:underline font-bold text-black">
                  +91 96464 30343
                </a>
              </p>
              <p className="flex items-center py-4 border-b-2 mt-4">
                <FaEnvelope className="text-[#03076a] text-xl mr-2" />
                <a href="mailto:info@detectiveagency.com" className="hover:underline font-bold text-black">
                  info@detectiveagency.com
                </a>
              </p>
              <p className="flex items-center py-4  mt-4">
                <FaMapMarkerAlt className="text-[#03076a] text-xl mr-2" />
                <span className="text-black font-bold">
                  1018, CCC, Vip Road , Zirakpur 140603, (Punjab)
                </span>
              </p>
            </div>
            
            {/* Social Media Icons */}
            <div className="flex space-x-3 mt-4 lg:mt-0 w-full lg:w-auto justify-center relative z-10">
          <a
            href="https://www.facebook.com/profile.php?id=61566256785834"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100 hover:bg-blue-500 transition duration-300"
          >
            <FaFacebook className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
          <a
            href="https://x.com/home"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100 hover:bg-blue-500 transition duration-300"
          >
            <FaTwitter className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
          <a
            href="https://www.instagram.com/cluefinderd/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100 hover:bg-blue-500 transition duration-300"
          >
            <FaInstagram className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
          <a
            href="https://in.pinterest.com/cluefinderd/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-gray-100  hover:bg-blue-500 transition duration-300"
          >
            <FaPinterest className="text-gray-800 hover:text-white text-xl lg:text-2xl" />
          </a>
        </div>

          </div>
          {/* Right Side Form */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  className="form-control p-4 border"
                  placeholder="Full Name"
                />
                <input
                  type="email"
                  className="form-control p-4 border"
                  placeholder="Email Address"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <input
                  type="text"
                  className="form-control p-4 border"
                  placeholder="Phone Number"
                />
                <input
                  type="text"
                  className="form-control p-4 border"
                  placeholder="Subject"
                />
              </div>
              <div className="mt-4">
                <textarea
                  className="form-control p-3 border  w-full"
                  rows="5"
                  placeholder="Write Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-[24vh] py-4 mt-6 bg-[#03076a] text-white  hover:bg-blue-700"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
