import React from "react";
import logo from "../assets/images/1978513938.png";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-16">
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 px-6">
        {/* Logo and Contact Section */}
        <div>
          <div className="bg-white p-4 rounded-md inline-block">
            <img src={logo} alt="Clue Finders Logo" className="w-28 mb-4" />
          </div>
          <p className="mt-4 text-gray-400 text-sm">Detective Agency</p>
          <div className="space-y-4 mt-6 border-t border-gray-600 pt-4">
            <p className="flex items-center">
              <FaPhoneAlt className="text-[#03076a] text-xl mr-3" />
              <a
                href="tel:+919646430343"
                className="hover:underline font-medium text-white"
              >
                +91 96464 30343
              </a>
            </p>
            <p className="flex items-center">
              <FaEnvelope className="text-[#03076a] text-xl mr-3" />
              <a
                href="mailto:info@detectiveagency.com"
                className="hover:underline font-medium text-white"
              >
                info@detectiveagency.com
              </a>
            </p>
            <p className="flex items-center">
              <FaMapMarkerAlt className="text-[#03076a] text-xl mr-3" />
              <span className="text-white font-medium">
                1018, CCC, VIP Road, Zirakpur 140603, (Punjab)
              </span>
            </p>
          </div>
        </div>

        {/* Explore Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4 uppercase">Explore</h4>
          <ul className="space-y-2 text-gray-300">
            <li>
              <Link to="/about" className="hover:text-blue-500 flex items-center">
                <span className="mr-2 text-xl">&rarr;</span> About
              </Link>
            </li>
            <li>
              <Link to="/blog" className="hover:text-blue-500 flex items-center">
                <span className="mr-2 text-xl">&rarr;</span> Blog
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-blue-500 flex items-center">
                <span className="mr-2 text-xl">&rarr;</span> Contact Us
              </Link>
            </li>
          </ul>
        </div>

        {/* Services Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4 uppercase">Services</h4>
          <ul className="space-y-2 text-gray-300">
            <li>
              <a
                href="/"
                className="hover:text-blue-500 flex items-center"
              >
                <span className="mr-2 text-xl">&rarr;</span> Personal Detective Services
              </a>
            </li>
            <li>
              <a
                href="/"
                className="hover:text-blue-500 flex items-center"
              >
                <span className="mr-2 text-xl">&rarr;</span> Corporate Detective Services
              </a>
            </li>
          </ul>
        </div>

        {/* Newsletter Section */}
        <div>
          <h4 className="text-lg font-semibold mb-6 text-center uppercase">Newsletter</h4>
          <form className="space-y-4">
            <input
              type="email"
              placeholder="Email Address"
              aria-label="Email Address"
              className="w-full px-4 py-3 rounded-md bg-gray-100 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-md transition"
            >
              Register Now
            </button>
          </form>
        </div>
      </div>

      {/* Background Pattern */}
      <div className="absolute inset-0">
        <div
          style={{
            backgroundImage: "url('/path-to-pattern.png')", // Replace with actual pattern URL
            backgroundSize: "cover",
            opacity: 0.1,
          }}
          className="h-full w-full"
        ></div>
      </div>
    </footer>
  );
};

export default Footer;
