import React from "react";
import { Link } from "react-router-dom";
import detectiveImage1 from "../assets/images/uploads/891184826.jpeg";
import detectiveImage2 from "../assets/images/uploads/288833802.jpg";
import detectiveImage3 from "../assets/images/uploads/1217319178.png";
import detectiveImage4 from "../assets/images/uploads/1377442022.jpg";
import detectiveImage5 from "../assets/images/uploads/1737484440.jpg";
import detectiveImage6 from "../assets/images/uploads/18427516.jpeg";
import detectiveImage7 from "../assets/images/uploads/1452023756.jpg";

const DetectiveServices = () => {
  return (
    <div className="bg-white px-2"> {/* Removed mt-[4vh] */}
      {/* Header Section */}
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 px-5 sm:px-8">
        <div className="mt-0 mb-0">
          <h2 className="text-4xl mt-10 relative font-rajdhani font-bold text-[#1c1c1c]">
            <span className="text-blue-500">__</span>
            Personal Detective Services
          </h2>
        </div>
        <div className="mt-5 mb-1">
          <p className="text-[#868686] font-rajdhani text-xl leading-[25px] font-semibold">
            India’s only Private Detective Agency offering a fast, meticulous, 
            and global service, gained from over 10 years of experience.
          </p>
        </div>
      </div>

      {/* Card Grid Section */}
      <div className="max-w-8xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
        {/* Map through detective services for dynamic generation */}
        {[
          {
            id: 1,
            image: detectiveImage1,
            title: "Pre Matrimonial Investigation",
            link: "/pre-matrimonial",
          },
          {
            id: 2,
            image: detectiveImage2,
            title: "Post Matrimonial Investigation",
            link: "/post-matrimonial",
          },
          {
            id: 3,
            image: detectiveImage3,
            title: "Loyalty Checks",
            link: "/loyalty-checks",
          },
          {
            id: 4,
            image: detectiveImage4,
            title: "Divorce Case Investigation",
            link: "/divorce-case",
          },
          {
            id: 5,
            image: detectiveImage5,
            title: "Physical Surveillance",
            link: "/physical-surveillance",
          },
          {
            id: 6,
            image: detectiveImage6,
            title: "Background Check",
            link: "/background-check",
          },
          {
            id: 7,
            image: detectiveImage7,
            title: "Sting Operation",
            link: "/sting-operation",
          },
        ].map((service) => (
          <div
            key={service.id}
            className="relative group bg-white rounded-lg shadow-md overflow-hidden"
          >
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-64 object-cover group-hover:scale-110 transition-all duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent">
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 p-4 text-center text-white">
                <Link to={service.link}>
                  <h2 className="text-2xl font-bold whitespace-nowrap">{service.title}</h2>
                </Link>
                <div className="mt-4">
                  <Link to={service.link}>
                    <button className="w-[50vh] py-2 text-lg bg-gray-800 text-white rounded-lg hover:bg-gray-700">
                      &rarr; Read More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetectiveServices;
