import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsTelephoneForward } from "react-icons/bs";
import { FiMenu, FiX } from "react-icons/fi";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [personalDropdown, setPersonalDropdown] = useState(false);
  const [corporateDropdown, setCorporateDropdown] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
    setPersonalDropdown(false); 
    setCorporateDropdown(false);
  };

  const togglePersonalDropdown = () => {
    setPersonalDropdown((prevState) => !prevState);
    setCorporateDropdown(false);  // Close corporate dropdown if personal is toggled
  };

  const toggleCorporateDropdown = () => {
    setCorporateDropdown((prevState) => !prevState);
    setPersonalDropdown(false);  // Close personal dropdown if corporate is toggled
  };

  const closeDropdowns = () => {
    setPersonalDropdown(false);
    setCorporateDropdown(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="bg-black bg-opacity-90 text-white shadow-md w-full">
      <nav className="flex items-center justify-between px-4 py-2 w-full bg-black text-white">
        
        {/* <a href="/" className="text-lg sm:text-2xl font-bold text-white">
          Clue Finder
        </a> */}

        {/* Navigation Links */}
        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } sm:flex flex-col sm:flex-row sm:items-center absolute sm:static top-14 sm:top-auto left-0 w-full sm:w-auto bg-black sm:bg-transparent z-40`}
        >
          <Link
            to="/"
            className="block px-12 py-2 hover:text-[#03076a]"
            onClick={closeDropdowns}
          >
            Home
          </Link>
          <Link
            to="/about"
            className="block px-6 py-2 hover:text-[#03076a]"
            onClick={closeDropdowns}
          >
            About Us
          </Link>

          {/* Personal Detective Services Dropdown */}
          <div className="relative w-full sm:w-auto ">
            <button
              className="block px-6 py-2 hover:text-[#03076a]"
              onClick={togglePersonalDropdown}
            >
              Personal Detective Services
            </button>
            {personalDropdown && (
              <div className="absolute bg-[#202020] z-10 w-full sm:w-64 left-0 sm:left-auto mt-[2vh]">
                <ul className="flex flex-col text-white">
                  <li>
                    <Link
                      to="/pre-matrimonial"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Pre Matrimonial Investigation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/post-matrimonial"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Post Matrimonial
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/loyalty-checks"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Loyalty Checks
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/divorce-case"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Divorce Case Investigation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/physical-surveillance"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Physical Surveillance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/background-check"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Background Check
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sting-operation"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Sting Operation
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* Corporate Detective Services Dropdown */}
          <div className="relative w-full sm:w-auto">
            <button
              className="block px-6 py-2 hover:text-[#03076a]"
              onClick={toggleCorporateDropdown}
            >
              Corporate Detective Services
            </button>
            {corporateDropdown && (
              <div className="absolute bg-[#202020] z-10 w-full sm:w-64 left-0 sm:left-auto mt-[2vh]">
                <ul className="flex flex-col text-white">
                  <li>
                    <Link
                      to="/pre-employment"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Pre Employment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/post-employment"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Post Employment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/labour-court-cases"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Labour Court Cases
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/undercover-agent"
                      className="px-4 py-2 block hover:text-blue-500"
                      onClick={closeDropdowns}
                    >
                      Undercover Agent
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <Link
            to="/blog"
            className="block px-4 py-2 hover:text-[#03076a]"
            onClick={closeDropdowns}
          >
            Blog
          </Link>
          <Link
            to="/contact"
            className="block px-4 py-2 hover:text-[#03076a]"
            onClick={closeDropdowns}
          >
            Contact
          </Link>
        </div>

        {/* Contact Section */}
        <div className="flex-1 flex items-center justify-center">
          <BsTelephoneForward className="text-xl sm:text-2xl text-[#03076a]" />
          <div className="ml-2 text-xs sm:text-sm md:text-lg text-center">
            <p>+919646430343</p>
            <p className="text-gray-400 text-[10px] sm:text-xs md:text-sm">
              Confidentiality Guaranteed
            </p>
          </div>
        </div>

        {/* Hamburger Menu */}
        <button
          className="text-2xl sm:hidden"
          onClick={toggleMobileMenu}
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? <FiX /> : <FiMenu />}
        </button>
      </nav>
    </div>
  );
};

export default Navbar;
