import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6">
  <h2 className="text-2xl font-semibold mb-4">
    Physical Surveillance by Cluefinder – Discreet, Accurate, and Reliable
  </h2>
  <h3 className="text-[#1c1c1c] text-lg leading-7 mb-4">
    At <strong>Cluefinder Detective Agency,</strong> we specialize in <strong>Physical Surveillance</strong> to gather real-time information and provide you with clear, undeniable evidence. Whether it's for personal or corporate investigations, our skilled investigators ensure that surveillance is conducted discreetly and efficiently.
  </h3>
  
  <h4 className="text-xl font-medium mb-2">What We Investigate:</h4>
  <ul className="list-disc pl-6 text-lg leading-7">
    <li>✅ Infidelity Cases – Track movements to verify suspected affairs.</li>
    <li>✅ Employee Monitoring – Observe employee behavior, ensuring company policies are followed.</li>
    <li>✅ Corporate Espionage – Watch for any signs of intellectual property theft or competitive sabotage.</li>
    <li>✅ Missing Persons – Locate individuals who are difficult to find or have gone missing.</li>
    <li>✅ Suspicious Activities – Monitor any activities or behaviors that raise concerns.</li>
  </ul>
  
  <h3 className="text-[#1c1c1c] text-lg leading-7 mt-4">
    Using the latest technology and investigative techniques, Cluefinder guarantees thorough, accurate surveillance with complete confidentiality. 
    We provide you with the facts, allowing you to make informed decisions.
  </h3>
</div>
  );
};

export default TextContent;
