import React from "react";
import Banner from "../component/PersonalServices/Surveillance/SurveillanceBanner";
import TextContent from "../component/PersonalServices/Surveillance/Surveillance";

const PreEmploymentPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default PreEmploymentPage;
