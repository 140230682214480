import React from "react";
import card1 from "../assets/images/card1akhil.png";
import card2 from "../assets/images/card2harpreet.png";
import card3 from "../assets/images/card3jasvir.png";

const testimonials = [
  { image: card1 },
  { image: card2 },
  { image: card3 },
  // Add more images as needed
];

const ClientTestimonials = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-center text-3xl font-semibold text-gray-800 mb-8">
          <span className="text-blue-500 h-20">__</span> Our Most Happy Clients
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex justify-center items-center p-4 "
            >
              <img
                src={testimonial.image}
                alt={`Client ${index + 1}`}
                className="w-full h-full object-cover rounded-md"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonials;
