import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsTelephoneForward } from "react-icons/bs";
import { FiMenu } from "react-icons/fi"; // Hamburger menu icon
import { IoMdClose } from "react-icons/io"; // Close icon

const Navbar = () => {
  const [personalDropdown, setPersonalDropdown] = useState(false);
  const [corporateDropdown, setCorporateDropdown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu on mobile
  const togglePersonalDropdown = () => {
    setPersonalDropdown((prevState) => !prevState);
    setCorporateDropdown(false);  // Close corporate dropdown if personal is toggled
  };

  const toggleCorporateDropdown = () => {
    setCorporateDropdown((prevState) => !prevState);
    setPersonalDropdown(false);  // Close personal dropdown if corporate is toggled
  };
  const closeDropdowns = () => {
    setPersonalDropdown(false);
    setCorporateDropdown(false);
  };
  return (
    <nav className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full flex justify-center space-x-10 bg-[#03076a] border-b-2 border-[#868686] py-2 z-50">
      <div className="flex flex-wrap justify-between w-full px-4 items-center">
        <Link
          to="/"
          className="px-4 py-2 rounded text-white text-lg transition duration-300 hover:text-blue-500 hover:bg-opacity-20"
        >
          Home
        </Link>
        <Link
          to="/about"
          className="px-4 py-2 text-white rounded text-lg transition duration-300 hover:text-blue-500 hover:bg-opacity-20"
        >
          About Us
        </Link>

        {/* Only show these on large screens, not on mobile */}
        <div className="relative w-full sm:w-auto ">
                    <button
          className="px-4 py-2 text-white rounded text-lg transition duration-300 hover:text-blue-500 hover:bg-opacity-20"
          onClick={togglePersonalDropdown}
                    >
                      Personal Detective Services
                    </button>
                    {personalDropdown && (
                      <div className="absolute bg-[#202020] z-10 w-full sm:w-64 left-0 sm:left-auto mt-[3vh]">
                        <ul className="flex flex-col text-white">
                          <li>
                            <Link
                              to="/pre-matrimonial"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Pre Matrimonial Investigation
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/post-matrimonial"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Post Matrimonial
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/loyalty-checks"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Loyalty Checks
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/divorce-case"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Divorce Case Investigation
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/physical-surveillance"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Physical Surveillance
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/background-check"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Background Check
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/sting-operation"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Sting Operation
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
        
                  {/* Corporate Detective Services Dropdown */}
                  <div className="relative w-full sm:w-auto">
                    <button
          className="px-4 py-2 text-white rounded text-lg transition duration-300 hover:text-blue-500 hover:bg-opacity-20"
          onClick={toggleCorporateDropdown}
                    >
                      Corporate Detective Services
                    </button>
                    {corporateDropdown && (
                      <div className="absolute bg-[#202020] z-10 w-full sm:w-64 left-0 sm:left-auto mt-[3vh]">
                        <ul className="flex flex-col text-white">
                          <li>
                            <Link
                              to="/pre-employment"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Pre Employment
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/post-employment"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Post Employment
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/labour-court-cases"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Labour Court Cases
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/undercover-agent"
                              className="px-4 py-2 block hover:text-blue-500"
                              onClick={closeDropdowns}
                            >
                              Undercover Agent
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <Link
                              to="/blog"
                              className="px-4 py-2 text-white rounded text-lg transition duration-300 hover:text-blue-500 hover:bg-opacity-20"
                              onClick={closeDropdowns}
                            >
                              Blog
                            </Link>

        {/* Hamburger Icon - Mobile Menu */}
        <div className="lg:hidden flex items-center">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white text-3xl"
          >
            {menuOpen ? <IoMdClose /> : <FiMenu />}
          </button>
        </div>

        {/* Contact Section */}
        <div className="flex items-center bg-black p-2 mt-2 md:mt-0">
          <div className="flex items-center justify-center text-white w-10 h-10 rounded-full">
            <BsTelephoneForward className="text-2xl text-[#03076a]" />
          </div>
          <div className="ml-4">
            <p className="text-lg text-white font-medium">+919646430343</p>
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu - Shows only when menuOpen is true */}
      {menuOpen && (
        <div className="lg:hidden absolute top-20 left-1/2 transform -translate-x-1/2 w-full bg-[#03076a] text-white p-4 space-y-4">
          <Link to="/" className="block text-lg">Home</Link>
          <Link to="/about" className="block text-lg">About Us</Link>
          <Link to="/blog" className="block text-lg">Blog</Link>
          <Link to="/contact" className="block text-lg">Contact</Link>
          {/* Personal Detective Services Links */}
          {/* <Link to="/pre-matrimonial" className="block text-lg">Pre Matrimonial Investigation</Link>
          <Link to="/post-matrimonial" className="block text-lg">Post Matrimonial</Link>
          <Link to="/loyalty-checks" className="block text-lg">Loyalty Checks</Link>
          <Link to="/divorce-case" className="block text-lg">Divorce Case Investigation</Link> */}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
