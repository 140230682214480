// src/pages/Home.js
import React from 'react';
import Banner from '../component/ContactUS/Banner'
import ContactPage from '../component/ContactUS/ContactUsPage'

const ContactUs = () => {
  return (
    <div className="contact-page">
      <Banner />
      <ContactPage />
      
    </div>
  );
};

export default ContactUs;
