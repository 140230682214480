import React from 'react';
import { FaWhatsapp, FaPhone } from 'react-icons/fa'; // Importing WhatsApp and Phone icons

const WhatsAppButton = () => {
  const whatsappNumber = "9646430343"; // Replace with your WhatsApp number
  const message = "Hello, I need more information!";
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
  const callUrl = `tel:${whatsappNumber}`; // Create a tel link for calling

  return (
    <div className="fixed bottom-5 right-5 flex flex-col items-center">
      <a
        href={whatsappUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-900 rounded-full p-3 mb-2 flex items-center justify-center transform transition duration-200 ease-in-out"
      >
        <FaWhatsapp className="text-white" style={{ fontSize: "30px" }} />
      </a>
      <a
        href={callUrl}
        className="bg-blue-600 rounded-full p-3 flex items-center justify-center transform transition duration-200 ease-in-out"
      >
        <FaPhone className="text-white" style={{ fontSize: "30px" }} />
      </a>
    </div>
  );
};

export default WhatsAppButton;