import React from "react";
import bannerImage from "../../../assets/images/uploads/1737484440.jpg";
import Navbar from "../../Navbar"; // Import the Navbar component

const HeaderBanner = () => {
  return (
    <div>
          <Navbar />

      {/* Banner Section */}
      <div className="relative h-full overflow-hidden">
        {/* Background Image with Overlay */}
        <div
          className="relative h-[400px] bg-cover bg-center flex items-center justify-center"
          style={{
            backgroundImage: `url(${bannerImage})`,
          }}
        >
          {/* Transparent Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Text Content */}
          <div className="relative text-center z-10">
            <h1 className="text-5xl font-bold text-white">Physical Surveillance
</h1>
            <p className="mt-2 text-xs font-bold text-white">
              <span className="text-[#d32e1b] text-xl text-left mr-2">__</span>
              "Eyes where it matters—reliable and confidential physical surveillance."


            </p>
          </div>
        </div>

        {/* Replace nav with Navbar component */}
      </div>
    </div>
  );
};

export default HeaderBanner;
