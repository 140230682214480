import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6 mt-8">
        <p className="text-[#868686;] text-lg leading-7 mb-4">
          Labour disputes can be complex and challenging, often leading to legal
          proceedings in labour courts. At{" "}
          <strong> Cluefinder Detective,</strong> we offer expert investigative
          services to support businesses and individuals involved in{" "}
          <strong>Labour Court Cases.</strong> Our goal is to uncover critical
          evidence, verify claims, and provide factual insights that can
          strengthen your position during legal disputes related to employment,
          worker rights, or contractual violations.{" "}
        </p>
        <p className="text-[#868686;] text-lg leading-7 mb-4">
          Labour court cases typically involve issues such as wrongful
          termination, wage disputes, discrimination, harassment, or violation
          of workplace policies. These cases can be emotionally and financially
          draining for all parties involved. Having a comprehensive
          investigation on your side can make a significant difference in the
          outcome. Our team at Cluefinder Detective works diligently to gather
          reliable information, allowing you to present a clear, well-supported
          case in court.{" "}
        </p>
        <p className="text-[#868686;] text-lg leading-7 mb-4">
          Here’s how our Labour Court Cases service helps:
        </p>
        <ul className=" mb-4 text-[#868686;]">
          <li className="mb-2">
            <strong>Employee Misconduct Investigations:</strong>
            In cases where an employee’s behavior is called into question—such
            as claims of theft, fraud, or serious breaches of company
            policies—we conduct thorough investigations to collect evidence of
            wrongdoing. This may involve surveillance, document analysis, or
            interviews with witnesses to corroborate the employer’s claims.
          </li>
          <li className="mb-2">
            <strong>
            Verification of Employment Records:
            </strong> Employment disputes often hinge on accurate documentation. We verify the employee’s work history, performance records, and any contractual agreements in place. This ensures that all employment-related documents align with the claims being made in court, helping either the employee or employer substantiate their arguments.
          </li>
          <li className="mb-2">
            <strong>
            Workplace Harassment and Discrimination Cases:
            </strong> 
            Allegations of workplace harassment or discrimination require detailed investigations to ensure a fair resolution. We discreetly gather testimonies, analyze internal communications, and review company policies to determine the validity of such claims. Our findings can play a crucial role in ensuring justice is served, whether for the claimant or the company.
          </li>
          <li className="mb-2">
            <strong>
            Wage and Compensation Disputes:
            </strong> Wage disputes and claims of unpaid overtime or wrongful deductions can escalate into labour court cases. We review payroll records, employment contracts, and company policies to assess whether the claims are justified, providing a clear account of the financial discrepancies involved.
          </li>
          <li className="mb-2">
            <strong>
            Surveillance for Fraudulent Claims:
            </strong>{" "}
            In certain cases, employees may file false claims of injury or unfair treatment in order to receive compensation or benefits. We offer discreet surveillance services to monitor the claimant’s activities, ensuring that their claims of injury or workplace-related incapacity are genuine.
          </li>
          <li className="mb-2">
            <strong>
            Mediation Support:
            </strong>{" "}
            Not all labour disputes need to go to court. Our investigations can also support mediation processes by providing unbiased, factual information that can help both parties come to a fair agreement outside of legal proceedings.
          </li>
        </ul>
        
      </div>
  );
};

export default TextContent;
