import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6">
  <h2 className="text-2xl font-semibold mb-4">Divorce Case Investigation
</h2>
  <h3 className="text-[#1c1c1c] text-lg leading-7 mb-4">
  Divorces can be a messy business and to win, it becomes a mammoth task for partners at times. The nature of divorces are very different from each other like cheating spouse, domestic violence, false alimony & maintenance etc hence, every Divorce Case requires an expertise for investigation; so that the case can be won on justice and truth.
</h3>
  <h2 className="text-2xl font-semibold mb-4">Divorce Case Investigation by Cluefinder – Gather the Evidence You Need</h2>
  <h3 className="text-[#1c1c1c] text-lg leading-7 mb-4">
    Going through a divorce can be a challenging and emotional experience. Cluefinder Detective Agency offers Divorce Case Investigation services to provide you with the necessary evidence to support your case and ensure a fair resolution.
    <br />
    <strong>What We Investigate:</strong>
    <ul className="list-disc ml-6">
      <li>✅ Infidelity Evidence – Collect proof of extramarital affairs or unfaithfulness.</li>
      <li>✅ Hidden Assets – Uncover hidden properties, finances, or valuables during asset division.</li>
      <li>✅ Child Custody – Investigate the suitability of a parent for custody, ensuring the child's best interests.</li>
      <li>✅ Spousal Misconduct – Document any behaviors or actions that could impact divorce settlements.</li>
      <li>✅ False Claims – Verify any misleading statements or accusations made by the other party.</li>
    </ul>
    With Cluefinder, you receive reliable, discreet, and actionable evidence that can strengthen your case in court. Our expert detectives ensure your privacy is respected while gathering the facts you need for a fair outcome.
  </h3>
</div>
  );
};

export default TextContent;
