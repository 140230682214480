import React from "react";
import Banner from "../component/CorporateServices/UnderCover/UnderBanner";
import TextContent from "../component/CorporateServices/UnderCover/Under";

const UndercoverAgentPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default UndercoverAgentPage;
