import React from 'react';
import blog1 from '../../assets/images/Blog1.png';
import blog2 from '../../assets/images/Blog2.png';
import blog3 from '../../assets/images/Blog3.png';

const BlogPage = () => {
  const blogPosts = [
    {
      id: 1,
      image: blog3,
      title: 'Why Consider a Pre-Marriage Investigation?',
      content:
        'Marriage is a significant step in life, and while planning the big day is exciting, addressing trust and transparency beforehand is just as crucial. The period leading up to marriage often involves building careers, saving for the future, and preparing for a shared life. Involving both parties fosters honest communication and helps identify potential concerns early, preventing them from escalating after marriage. Addressing these issues proactively allows couples to work through challenges together, avoiding misunderstandings or future regrets. Additionally, this approach can save significant costs by mitigating the risk of conflicts that could lead to a costly separation or divorce. Investing in a pre-matrimonial investigation not only strengthens trust but also helps build a solid foundation for a healthy, long-lasting marriage. For those who value transparency and want to ensure a smooth transition into married life, hiring a professional pre-matrimonial detective agency is a wise and practical choice.',
      date: 'November 23, 2024',
    },
    {
      id: 2,
      image: blog2,
      title: 'How to Identify a Genuine Detective Agency in Zirakpur?',
      content:
        'Identifying a genuine detective agency in Zirakpur requires careful consideration to ensure you receive reliable and professional services. Start by verifying the agency credentials and registration, ensuring it operates legally and ethically. A trustworthy agency like Cluefinder stands out with its reputation for professionalism and expertise. Look for experience in handling cases similar to yours, as this demonstrates capability and understanding. Client reviews and testimonials can provide valuable insights into their track record and reliability. Transparency is key, so ensure the agency offers clear terms of service, maintains confidentiality, and provides regular updates on your case. Cluefinder prides itself on ethical practices, skilled investigators, and a commitment to delivering accurate results. For a dependable partner in solving personal or professional matters, Cluefinder Detective Agency is a name you can trust in Zirakpur.',
      date: 'November 20, 2024',
    },
    {
      id: 3,
      image: blog1,
      title: 'Is it legal to investigate your partner’s background before marriage?',
      content:
        'Investigating your partner background before marriage is generally legal, as long as it is conducted within the boundaries of the law and respects privacy rights. Hiring a professional pre-matrimonial detective agency ensures that the investigation is done ethically and legally. These agencies typically gather publicly available information or conduct discreet inquiries without infringing on personal liberties. Common areas of investigation include employment verification, financial stability, past relationships, and criminal records. However, it crucial to avoid unauthorized actions such as hacking, stalking, or any form of surveillance that violates privacy laws. Transparency with your partner about the need for such an investigation can also help build trust. To stay on the right side of the law, always engage a reputable and licensed detective agency like Cluefinder, which operates ethically and complies with legal regulations.',
      date: 'November 15, 2024',
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-10">
      {/* Blog Title */}
      <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-8 mt-4 text-center">
        Blog Page
      </h1>

      {/* Blog Cards */}
      <div className="space-y-6">
        {blogPosts.map((post) => (
          <BlogCard
            key={post.id}
            image={post.image}
            title={post.title}
            content={post.content}
            date={post.date}
          />
        ))}
      </div>
    </div>
  );
};

const BlogCard = ({ image, title, content, date }) => {
  return (
    <div className="group flex flex-col lg:flex-row bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl">
      {/* Left Side Image */}
      <div className="w-full lg:w-1/3 flex-shrink-0">
        <img
          src={image}
          alt={title}
          className="w-full h-full"
          style={{ maxHeight: '100%' }}
        />
      </div>

      {/* Right Side Content */}
      <div className="w-full lg:w-2/3 p-6 flex flex-col justify-between">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 group-hover:text-blue-600 transition-colors">
          {title}
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">{content}</p>
        <div className="text-sm text-gray-500">{date}</div>
      </div>
    </div>
  );
};


export default BlogPage;