import React from "react";
import Banner from "../component/CorporateServices/PostEmplyment/PostBanner";
import TextContent from "../component/CorporateServices/PostEmplyment/PostEmp";

const PostEmploymentPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default PostEmploymentPage;
